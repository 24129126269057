import React from 'react';
import Header from '../Header/Header'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import { useNavigate } from "react-router-dom"

const MainLayout = ({ children }) => {

    const navigate = useNavigate();

    const handleChange = (event, value) => {
        navigate(`/items/${value}`)
    };

    return (
        <>
            <Header />
            <main>{children}</main>
            <div style={{
                display: "flex", alignItems: "center", justifyContent: "center",
                marginTop: "5%", marginBottom: "3%"
            }}>

                

            </div>
        </>
    )
}

export default MainLayout;