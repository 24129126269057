import React, { useEffect } from 'react';
import logo from './mland.jpg'
import Button from '../Button/Button'
import { useNavigate } from "react-router-dom"
import { useWeb3React } from "@web3-react/core"
import { InjectedConnector } from '@web3-react/injected-connector'
import './header.css'


const injected = new InjectedConnector({
    supportedChainIds: [1, 3, 4, 5, 42, 56],
})



const Header = () => {

    const navigate = useNavigate();

    const { active, account, library, connector, activate, deactivate } = useWeb3React();

    console.log("ACTIVE", account);

    useEffect(() => {
        async function sync(){
            try {
                await activate(injected)
            } catch (ex) {
                console.log(ex)
            }
        }
        sync()
        console.log("IS ACTIVE", active)
    }, [active])

    async function connect() {        
        try {
            await activate(injected)
            console.log("CONNECT", injected)
        } catch (ex) {
            console.log("Error", ex)
        }
    }

    async function disconnect() {
        console.log("DISCONNECT")
        try {
            deactivate()
        } catch (ex) {
            console.log(ex)
        }
    }


    return (
        <>
            <div class="topnav">
                <a class="active">
                    <img style={{cursor: "pointer"}} onClick={()=>navigate('/')} src={logo} width="53px" height="50px" />
                </a>
                <a style={{ marginTop: "25px", fontWeight: "bold", color: "black", cursor: "pointer" }} onClick={()=>navigate('/')}>MetaLandYield Dashboard</a>
                <div class="topnav-right">
                    <a  onClick={()=> window.open("https://metaland.metaclub.biz/", "_blank") } style={{ marginTop: "10px", cursor: "pointer" }}><Button>Website</Button></a>
                    {
                        active && <a onClick={() => disconnect} style={{ marginTop: "10px" }}><Button >{
                            `${account.slice(0,5)}...${account.slice(-5)}`
                            }</Button></a>
                    }
                    {
                        !active && <a onClick={() => connect()} style={{ marginTop: "10px" }}><Button className="topnav">Connect</Button></a>
                    }
                </div>
            </div>
            <div className="line"></div>
        </>
    )
}

export default Header;

