import logo from './logo.svg'
import MainLayout from './components/Layouts/MainLayout'
import Grid from './components/Grid/Grid'
import Pool from './components/Pool/Pool'
import './polyfill'
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom'
import { Web3ReactProvider } from '@web3-react/core'
import Web3 from 'web3'
import './App.css'

function App () {
  function getLibrary (provider) {
    return new Web3(provider)
  }

  return (
    <Web3ReactProvider getLibrary={getLibrary}>
      <Router>
        <div>
          {/* A <Routes> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
          <Routes>
            <Route
              path='/'
              element={
                <MainLayout>
                  <Grid />
                </MainLayout>
              }
            />
            <Route
              path='/items/:id'
              element={
                <MainLayout>
                  <Grid />
                </MainLayout>
              }
            />
            <Route
              path='/pool/:id'
              element={
                <MainLayout>
                  <Pool />
                </MainLayout>
              }
            />
          </Routes>
        </div>
      </Router>
    </Web3ReactProvider>
  )
}

export default App
