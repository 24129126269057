import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import { makeStyles } from '@material-ui/core/styles'
import { useNavigate } from 'react-router-dom'
import { createClient } from '@supabase/supabase-js'
import CountUp from 'react-countup'
import { ethers } from 'ethers'
import axios from 'axios'
import './grid.css'

const provider = new ethers.providers.WebSocketProvider(
  'wss://speedy-nodes-nyc.moralis.io/ea44ed2fcb54061eaf453c1a/bsc/mainnet/ws'
)
const supabaseUrl = 'https://xvsiitlrwsnmkmyktqfu.supabase.co'
const supabaseKey = process.env.REACT_APP_SUPABASE_KEY
const supabase = createClient(supabaseUrl, supabaseKey)

const addresses = {
  bnb: '0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c',
  treasuryAddress: '0xC3859a4EE92E027c2afdd265094A9A8bfe99df3B'
}

const bnbAbi = [
  // Some details about the token
  'function name() view returns (string)',
  'function symbol() view returns (string)',

  // Get the account balance
  'function balanceOf(address) view returns (uint)',

  // Send some of your tokens to someone else
  'function transfer(address to, uint amount)',

  // An event triggered whenever anyone transfers to someone else
  'event Transfer(address indexed from, address indexed to, uint amount)'
]

const useStyles = makeStyles(theme => ({
  root: {
    [theme.breakpoints.down('xs')]: {
      alignItems: 'flex-end' // push the dialog to bottom
    }
  },
  paper: {
    // make the content full width
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      maxWidth: '100%',
      width: '100%'
    }
  }
}))

const Grid = () => {
  let navigate = useNavigate()
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const [data, setData] = useState([])
  const [nftData, setNFTData] = useState([])
  let treasuryVal = 0
  //const openDialog = () => setOpen(true);
  const toggleDialog = () => {
    setOpen(!open)
  }

  useEffect(() => {
    load()
  }, [])
  const load = async () => {
    //const bnbContract = new ethers.Contract(addresses.bnb, bnbAbi, provider);
    let mcap = 0
    let price = 0

    await axios
      .get(
        'https://api.pancakeswap.info/api/v2/tokens/0x553af511a05fe87476e49374983eeca0d63bca90'
      )
      .then(async payload => {
        let info = payload.data.data.price
        info = parseFloat(info)
        price = info
        console.log('PRIX', price)
        mcap = info * 600000000
      })
    let balanceTreasury = await provider.getBalance(addresses.treasuryAddress)
    balanceTreasury = ethers.utils.formatUnits(balanceTreasury, 18)

    let { data: treasury, error } = await supabase.from('treasury').select('*')

    treasuryVal = treasury[0].value

    let { data: nfts, error: nftError } = await supabase
      .from('nfts')
      .select('*')
    console.log('NFTY DATA', treasury)

    setNFTData(nfts)

    const mData = [
      {
        id: 1,
        name: 'Treasury Value',
        price: treasuryVal,
        ongoing: true,
        img: 'https://s3.envato.com/files/279241795/1_CTexmap.jpg',
        pref: '$ '
      },
      {
        id: 2,
        name: 'Marketcap',
        price: mcap,
        ongoing: true,
        img:
          'https://i.pinimg.com/736x/a9/ff/87/a9ff87847097a0d637956f4df7f0a669--low-poly-the-wall.jpg',
        pref: '$ '
      },
      {
        id: 5,
        name: 'Price',
        price: price,
        ongoing: true,
        img:
          'https://external-preview.redd.it/yi-Ib2PbpXdOhf_rQuxbOhiWKtWLq8jRMBORMpz0wvg.png?format=pjpg&auto=webp&s=bd3a1872ffd20efb5176d81f2d8a36d5f4d14f47',
        pref: '$ '
      }
    ]

    setData(mData)
  }
  //https://codesandbox.io/s/material-ui-dialog-sample-forked-07vbw?file=/src/index.js
  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'center'
        }}
      >
        <p
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            textDecoration: 'underline'
          }}
        >
          Treasury
        </p>
      </div>
      <div class='grid-container'>
        {data.map((val, idx) => {
          return (
            <div class='grid-item'>
              <div>
                <p style={{ fontSize: '20px', fontWeight: 'bold' }}>
                  {val.name}
                </p>
                <CountUp
                  style={{ fontSize: '20px', fontWeight: 'bold' }}
                  duration={2}
                  end={val.price}
                  decimals={val.name == 'Price' ? 6 : 2}
                  prefix={val.pref}
                />
              </div>
            </div>
          )
        })}
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignContent: 'center',
          justifyContent: 'center'
        }}
      >
        <p
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
            textDecoration: 'underline'
          }}
        >
          Metaverse Assets
        </p>
      </div>
      <div class='grid-container'>
        {nftData && nftData.map((val, idx) => {
          return (
            <div class='nft-item'>
              <div  onClick={()=> window.open(val.link, "_blank") } >
                <img                  
                  src={val.image}
                  height='60%'
                  width='100%'
                />
              </div>
            </div>
          )
        })}
      </div>
    </>
  )
}

export default Grid
