import React from 'react';
import './button.css';

const Button = ({ children }) => {
    return (
        <div className="button">
            <p style={{color: "white", fontWeight: "bold"}}>{children}</p>
        </div>
    )
}

export default Button;