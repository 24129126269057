import React from 'react';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import './pool.css'

const src = "https://external-preview.redd.it/yi-Ib2PbpXdOhf_rQuxbOhiWKtWLq8jRMBORMpz0wvg.png?format=pjpg&auto=webp&s=bd3a1872ffd20efb5176d81f2d8a36d5f4d14f47"

const Pool = () => {
    return (
        <>
            <div class="card-container">
                <div class="card-item-2">
                    <div style={{ marginBottom: "5%" }}>
                        <p style={{fontSize: "20px", fontWeight: "bold"}}>CAPITAL ALLOCATED</p>
                    </div>

                    <div style={{ marginBottom: "5%" }}>
                        <p  style={{fontSize: "20px", fontWeight: "bold"}}>1.2 / 3 (ETH)</p>
                    </div>

                    <div style={{ marginBottom: "5%" }}>
                        <p style={{fontSize: "20px", fontWeight: "bold"}}>75% FILLED</p>
                    </div>

                    <div style={{ marginBottom: "5%" }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: "5%" }}>
                            <Box sx={{ width: '100%', mr: 1 }}>
                                <LinearProgress variant="determinate" value={75} />
                            </Box>
                            <Box sx={{ width: 100 }}>
                                <Typography variant="body2" color="text.secondary">{`${Math.round(
                                    75,
                                )}%`}</Typography>
                            </Box>
                        </Box>
                    </div>

                    <div className="btn-card">
                        <p>CONTRIBUTE</p>
                    </div>

                </div>
                <div class="card-item">
                    <div style={{marginTop: "5%", marginBottom: "5%"}}>
                        <img src={src} width="80%" height="50%" />
                    </div>
                    <div>
                        <p style={{fontSize: "20px", fontWeight: "bold"}}>Founders' Private Plot #1482</p>
                    </div>
                    <div>
                        <p style={{fontSize: "20px", fontWeight: "bold"}}>3 ETH</p>
                    </div>
                    <div className="btn-card">
                        <p>OPENSEA</p>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Pool;
